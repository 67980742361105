import React, { useState, useEffect } from 'react';
import './login.scss';
import { FaSpinner } from 'react-icons/fa';

const SignInForm = () => {
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [callbackUrl, setCallbackUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const callbackUrl = params.get('callback_url');
        if (!callbackUrl) {
            window.history.back();
        }
        setCallbackUrl(callbackUrl);
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            setIsLoading(true);
            const response = await window.api.post('auth/login', { username, password });
            const { access_token, refresh_token } = response.data;
            window.location.href = `${callbackUrl}?access_token=${access_token}&refresh_token=${refresh_token}`;
        } catch (error) {
            setIsLoading(false);
            if (error?.response?.data?.message === 'The user credentials were incorrect.') {
                window.showGlobalAlert('Usuário ou senha incorretos', 'error');
            } else {
                window.showGlobalAlert('Ocorreu um erro na autenticação. Por favor, tente novamente mais tarde', 'error');
            }
            console.error('Erro ao autenticar', error);
        }
    }

    return (
        <div className="Container-login">
            <div className="heading">Sign In</div>
            <form className="form" onSubmit={handleSubmit}>
                <input
                    required
                    className="input"
                    type="string"
                    name="login"
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Usuário"
                />
                <input
                    required
                    className="input"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Senha"
                />
                <span className="forgot-password">
                    <a href="https://portal.clickideia.com.br/esqueci-minha-senha">Esqueceu a senha?</a>
                </span>
                <button className="login-button flex items-center justify-center" type="submit" disabled={isLoading} >
                    {isLoading ? (
                        <FaSpinner className="animate-spin h-5 w-5 text-white" />
                    ) : (
                        'Entrar'
                    )}
                </button>
            </form>
            <div className="social-account-container">
                <span className="title">Ou entre com</span>
                <div className="social-accounts">
                    <button className="social-button google">
                        <img
                            src="https://developers.google.com/identity/images/g-logo.png"
                            alt="Google Logo"
                        />
                    </button>
                </div>
            </div>
            <span className="agreement">
                <a href="https://ajuda.clickideia.com.br/login">Ajuda com acesso</a>
            </span>
        </div >
    );
};

export default SignInForm;
